
.img-box
  width 64px
  height 64px
  margin 0 auto
  background-color: $color-warning
  border-radius 50%
  padding 12px

.img-end
  width 100%
  height 100%
  max-width 100px
  object-fit contain
  object-position center

.qr-code-box
  background-color: #dedede
  width 140px
  height 140px
  margin 0 auto
  display flex
  justify-content center
  align-items center
  &.active {
	background-color: #fff
  }
  div {
	width: fit-content
	height: fit-content
  }
  .btn-show-qr-code {
    background-color: #000
	color: #fff
	width: fit-content
	height: auto
	padding: 8px
	aspect-ratio: 1 / 1
	border-radius: 50%
	display: flex;
    align-items: center;
	font-size: 12px;
	cursor: pointer;
  }

.actions-container {
  position: fixed
  bottom: 0
  left: 0

  width: 100%
  padding: 8px 16px
  z-index: 10

  background-color: #fff
  box-shadow: 1px 1px 2px 1px $color-gray-200;
}
